<template>
  <div class="card">
    <div
      v-b-toggle="`collapseSabbaticals`"
      class="card-header"
    >
      <h3
        :id="`field-8`"
        class="cart-title"
      >
        <a data-action="collapse">{{ field.label ? field.label : field.name }}</a>
      </h3>
      <b-tooltip
        :key="`field-custom-8`"
        placement="topleft"
        boundary="document"
        triggers="hover"
        :target="`field-8`"
        :title="field.tooltip"
      />
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
          <!-- <li>
            <a
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Edit"
              @click="redirectuser($event, [{user_id: userId}, 'researcher.industrial-property.index'])"
            ><i data-feather="edit-3" /></a>
          </li> -->
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseSabbaticals`"
      v-model="isToggled"
    >
      <div
        class="card-content"
      >
        <div class="card-body">
          <div
            v-if="loading"
            class="loading position-relative"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
          <div
            v-else
            class="row"
          >
            <div class="col">
              <ul
                v-for="sabbatical, index in scientificSabbaticals"
                :key="index"
                class="abridged-cv__list"
              >
                <li><strong>{{ sabbatical.date_formatted }} - {{ sabbatical.end_date_formatted }}</strong>
                  - <em>{{ sabbatical.status ? sabbatical.status.status : '---' }}</em><a
                    class="ms-1 btn btn-sm bg-light-secondary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit"
                    @click="redirectuser($event, [{ id: sabbatical.id }, 'researcher.administration.sabbaticals.edit'])"
                  ><i data-feather="edit-3" /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse, BTooltip } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
    BTooltip,
  },
  props: {
    userId: { type: Number, required: true, default: () => {} },
    yearBetween: { type: Object, required: true, default: () => {} },
    field: { type: Object, required: true },
  },
  data() {
    return {
      loading: true,
      isToggled: true,
    }
  },
  computed: {
    ...mapGetters({
      scientificSabbaticals: 'users/scientificSabbaticals',
      toggled: 'preview/toggled',
    }),
  },
  watch: {
    userId() {
      this.reloadData()
    },
    toggled() {
      if (this.toggled !== this.isToggled) {
        this.isToggled = this.toggled
      }
    },
    yearBetween() {
      this.reloadData()
    },
  },
  async mounted() {
    this.reloadData()
  },
  methods: {
    async reloadData() {
      this.loading = true
      this.isToggled = this.toggled
      await this.$store.dispatch('users/fetchUserScientificSabbaticals', { userId: this.userId, yearBetween: this.yearBetween })
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      this.loading = false
    },
    async redirectuser(event, redirection) {
      event.stopPropagation()
      this.$emit('goToIndex', redirection)
    },
  },
}
</script>
