<template>
  <div class="card">
    <div
      v-b-toggle="`collapsePersonalInformation`"
      class="card-header"
    >
      <h3
        class="cart-title"
      >
        <a data-action="collapse">Researcher's information</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapsePersonalInformation`"
      visible
    >
      <div
        class="card-content collapse show"
      >
        <div class="card-body">
          <div
            v-if="loading"
            class="loading position-relative"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
          <div
            v-else
            class="row"
          >
            <div
              class="col-sm-4"
            >
              <div class="d-flex justify-content-center">
                <img
                  :src="scientificPersonalInfo.avatarUrl ? scientificPersonalInfo.avatarUrl : require('@/assets/images/avatars/user_default.png')"
                  alt="Avatar"
                  class="img-fluid custom-img-max-size"
                  @click="openFileInput"
                >
              </div>
            </div>
            <input
              ref="fileInput"
              type="file"
              style="display: none"
              @change="handleFileChange"
            >
            <div
              class="col-sm-8"
            >
              <h3>{{ scientificPersonalInfo.name }}</h3>
              <h5>{{ scientificPersonalInfo.area }}</h5>
              <p>
                {{ scientificPersonalInfo.host }}
              </p>
              <p
                v-if="Object.keys(scientificPersonalInfo.ercs).length > 0"
                :class="Object.keys(scientificPersonalInfo.additional).length > 0 ? '' : 'mb-4'"
              >
                <span
                  class="badge bg-light-info me-1"
                >{{ scientificPersonalInfo.ercs['level3'] ? scientificPersonalInfo.ercs['level3']['code'] : (scientificPersonalInfo.ercs['level2'] ? scientificPersonalInfo.ercs['level2']['code'] : '-') }} : {{ scientificPersonalInfo.ercs['level3'] ? scientificPersonalInfo.ercs['level3']['name'] : (scientificPersonalInfo.ercs['level2'] ? scientificPersonalInfo.ercs['level2']['name'] : '-') }}</span>
              </p>
              <p
                v-if="Object.keys(scientificPersonalInfo.additional).length > 0"
                class="mb-4"
              >
                <span
                  v-for="(additional, index) in scientificPersonalInfo.additional"
                  :key="index"
                  class="badge bg-light-info me-1"
                >{{ additional['level3'] ? additional['level3']['code'] : (additional['level2'] ? additional['level2']['code'] : '-') }} : {{ additional['level3'] ? additional['level3']['name'] : (additional['level2'] ? additional['level2']['name'] : '-') }}</span>
              </p>
              <template
                v-if="scientificPersonalInfo.keywords.length > 0"
              >
                <h4>Keywords</h4>
                <div class="d-flex flex-wrap">
                  <div
                    v-for="singleWord, index in scientificPersonalInfo.keywords"
                    :key="index"
                  >
                    <span
                      class="badge badge-light-dark me-1 mb-2"
                    >{{ singleWord.word }}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    userId: { type: Number, required: true, default: () => {} },
  },
  data() {
    return {
      loading: true,
      icon_path: '',
    }
  },
  computed: {
    ...mapGetters({
      scientificPersonalInfo: 'users/scientificPersonalInfo',
    }),
  },
  watch: {
    userId() {
      this.reloadData()
    },
  },
  async mounted() {
    this.reloadData()
  },
  methods: {
    async reloadData() {
      this.loading = true
      this.isToggled = this.toggled
      await this.$store.dispatch('users/fetchUserScientificPersonalInfo', { userId: this.userId })
      this.icon_path = this.scientificPersonalInfo ? this.scientificPersonalInfo.avatarUrl : ''
      this.loading = false
    },
    openFileInput() {
      this.$refs.fileInput.click()
    },
    async handleFileChange(event) {
      const file = event.target.files[0]
      if (file || this.scientificPersonalInfo.avatar.length > 0) {
        this.loading = true
        if (file) {
          file.weight = (file.size / 1024 / 1024).toFixed(2)
          const newAvatar = {
            name: file.name,
            original_name: file.name,
            weight: (file.size / 1024 / 1024).toFixed(2),
            extension: file.type,
            file,
          }
          const userAvatar = {
            avatar: newAvatar,
            user_id: this.userId,
          }
          await this.$store.dispatch('users/uploadAvatar', userAvatar)
          this.newAvatar = []
          this.reloadData()
        }
      }
    },
  },
}
</script>
